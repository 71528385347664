// This is a file that can be overridden by the application in order to override
// some of the Foundation/Decidim SCSS settings and variables after the default
// settings have been loaded.
//
// To override styles use decidim_application.scss
//
// By default this is empty.

// // Variables
//
// $primary: #ef604d;
// $secondary: #599aa6;
// $success: #57d685;
// $warning: #ffae00;
// $alert: #ec5840;
//
// $proposals: #238ff7;
// $actions: #57d685;
// $debates: #fa6c96;
// $meetings: #fabc6c;
//
// $twitter: #55acee;
// $facebook: #3b5998;
// $google: #dd4b39;

$primary: #009c82;
$secondary: #00505a;
$success: #009c82;
$warning: #fcf389;
$alert: #eb640a;

$proposals: #b6b0e9;
$actions: #b6b0e9;
$debates: #b6b0e9;
$meetings: #b6b0e9;

$light: #e8e8e8; // Alternativt beige: #f4f0df;
