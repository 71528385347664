@font-face {
    font-family: 'Siri';
    src: url('fonts/SiriCoreWeb/SiriCore-Regular.eot');
    src: url('fonts/SiriCoreWeb/SiriCore-Regular.eot?') format('embedded-opentype'),
        url('fonts/SiriCoreWeb/SiriCore-Regular.woff') format('woff'),
        url('fonts/SiriCoreWeb/SiriCore-Regular.ttf') format('opentype');
    font-weight: lighter;
    font-style: lighter;
}

@font-face {
    font-family: 'Siri';
    src: url('fonts/SiriCoreWeb/SiriCore-Italic.eot');
    src: url('fonts/SiriCoreWeb/SiriCore-Italic.eot?') format('embedded-opentype'),
        url('fonts/SiriCoreWeb/SiriCore-Italic.woff') format('woff'),
        url('fonts/SiriCoreWeb/SiriCore-Italic.ttf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Siri';
    src: url('fonts/SiriCoreWeb/SiriCore-Light.eot');
    src: url('fonts/SiriCoreWeb/SiriCore-Light.eot?') format('embedded-opentype'),
        url('fonts/SiriCoreWeb/SiriCore-Light.woff') format('woff'),
        url('fonts/SiriCoreWeb/SiriCore-Light.ttf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Siri';
    src: url('fonts/SiriCoreWeb/SiriCore-Bold.eot');
    src: url('fonts/SiriCoreWeb/SiriCore-Bold.eot?') format('embedded-opentype'),
        url('fonts/SiriCoreWeb/SiriCore-Bold.woff') format('woff'),
        url('fonts/SiriCoreWeb/SiriCore-Bold.ttf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Siri';
    src: url('fonts/SiriCoreWeb/SiriCore-SemiBold.eot');
    src: url('fonts/SiriCoreWeb/SiriCore-SemiBold.eot?') format('embedded-opentype'),
        url('fonts/SiriCoreWeb/SiriCore-SemiBold.woff') format('woff'),
        url('fonts/SiriCoreWeb/SiriCore-SemiBold.ttf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Siri';
    src: url('fonts/SiriCoreWeb/SiriCore-BoldItalic.eot');
    src: url('fonts/SiriCoreWeb/SiriCore-BoldItalic.eot?') format('embedded-opentype'),
        url('fonts/SiriCoreWeb/SiriCore-BoldItalic.woff') format('woff'),
        url('fonts/SiriCoreWeb/SiriCore-BoldItalic.ttf') format('opentype');
    font-weight: bold;
    font-style: italic;
}
