// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "modules/mini_footer";
@import "modules/white_theme";
@import "modules/fonts_poseidon";

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
body,
html {
    font-family: 'Siri', 'Source Sans Pro', Helvetica, Roboto, Arial, sans-serif;
}

.hero-heading {
    text-shadow: none;
}
