.mini-footer .row {
    display: flex;
    align-items: center;
    justify-content: center;

    .decidim-logo {
        display: flex;
        flex-grow: 0;
        width: auto;
        margin: 0 .5rem;
    }

    img {
        height: 1rem;
    }

    .mf-text {
        font-size: 80%;
        line-height: 1rem;

        a {
            font-weight: 600;

            &:hover {
                color: white;
            }
        }
    }
}
